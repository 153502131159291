export function HomePage() {
    return (
        <div className="jumbotron">
            <h1 className="display-4">Home</h1>            
            <p className="lead">Welcome to Arcane ECM</p>
            <hr className="my-4"></hr>
            <p>Arcane ECM is an Enterprise Content Management solution for Salesforce. Store your files anywhere. View and manage them from Salesforce.</p>
            <img src="/main.jpg" width="1200" height="688" alt="Arcane Scale Enterprise Content Management" />
            
            <p>Store or move files outside of Salesforce to reduce storage costs</p>
            <p>Store files in your preferable cloud provider (such as AWS S3, GCS, Azure Blob, Box, Dropbox, etc.) or on-prem systems to comply with various regulations (e.g. in an immutable storage for audit purposes)</p>
            <p>Store files in your unified enterprise store or data lake and leverage downstream processing capabilities such as ML, analytics, etc.</p>
            <p>Integrate with or offload files to Financial Core Systems, EMR/EHR or ERP.</p>
            <p className="lead">
                <a className="btn btn-primary btn-lg" href="https://www.arcanescale.com/#services" role="button" target="_blank">Learn more</a>
            </p>
        </div>
        
    );
}