import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";

export function NavigationComponent() {

    const [isLoggedIn, setLoggedIn] = useState(false);
    const [greeting, setGreeting] = useState('');

    function changeUserState() {
        const userData = sessionStorage.getItem('user');

        setLoggedIn( userData != null ); 
        
        if(userData) {
            const user = JSON.parse(userData);
            setGreeting( !user.first_name ? user.username : user.first_name + (user.last_name ? ' ' + user.last_name: '') + ` (${user.username})` );            
        } else {
            setGreeting('');
        }        
    }

    function storageEventHandler() {                
        changeUserState();            
        window.removeEventListener('storage', storageEventHandler, false); // disposing listener immediately, otherwise it might cause prolifiration of event listeners for multple login/logout actions
    }    

    useEffect(() => {                
        window.addEventListener('storage', storageEventHandler, false );        
        changeUserState();    
    }, []);

    return (
        <>                        
            <ul className="nav nav-underline me-auto">                    
                <li className="nav-item">
                    <NavLink to="/" className="nav-link">Home</NavLink>                
                </li>
                {isLoggedIn ? (
                    <>                    
                        <li className="nav-item">
                            <NavLink to="/profile" className="nav-link">Profile</NavLink>
                        </li>                        
                    </>
                    ) : (
                    <>
                        <li className="nav-item">
                            <NavLink to="/login" className="nav-link">Login</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/registration" className="nav-link">Registration</NavLink>
                        </li> 
                    </>
                    )}
            </ul>            
            { isLoggedIn &&<div className="d-flex">Welcome, {greeting} &nbsp;&nbsp;&nbsp; <NavLink to="/logout" className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
</svg> Log out</NavLink></div> }
        </> 
    );
}